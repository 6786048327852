import { addDataToQueryString } from '@/helpers/strings'
import { CreateRes, PaginatedData, PaginationReq, ServerRes } from './api.types'
import {
  FUND_METRIC,
  FUNDS,
  FUNDS_INVESTMENT,
  FUNDS_OVERVIEW,
  FUNDS_PORTFOLIO,
  FUNDS_UPDATES,
} from './endpoints'
import api from './api'

export enum InvestmentRound {
  'Angel Investments' = 'Angel Investments',
  'Pre Seed' = 'Pre Seed',
  'Seed' = 'Seed',
  'Pre-Series A' = 'Pre-Series A',
  'Series A' = 'Series A',
  'Others' = 'Others',
}

export enum SecurityType {
  'Commodity Futures' = 'Commodity Futures',
  'Cryptocurrencies' = 'Cryptocurrencies',
  'Common Stock' = 'Common Stock',
  'Treasury Bills(T-Bills)' = 'Treasury Bills(T-Bills)',
  'Bonds' = 'Bonds',
  'Notes' = 'Notes',
  'Venture Capital Preferred Stocks' = 'Venture Capital Preferred Stocks',
  'Preferred Stock' = 'Preferred Stock',
  'Convertible Securities' = 'Convertible Securities',
  'Options' = 'Options',
  'SAFE(Simple Agreement for Future Equity)' = 'SAFE(Simple Agreement for Future Equity)',
  'Futures' = 'Futures',
  'Government Bonds' = 'Government Bonds',
  'Mortgage Backed Securities' = 'Mortgage Backed Securities',
  'Warrants' = 'Warrants',
  'Treasury Securities' = 'Treasury Securities',
  'Swaps' = 'Swaps',
  'Collateralized Debt Securities' = 'Collateralized Debt Securities',
  'Real Estate Investments Trusts(REITs)' = 'Real Estate Investments Trusts(REITs)',
}

export type FundInfoReqDTO = {
  name: string
  structure: string
  registration_id: string
  physical_address: string
  email: string
  phone_number: string
  website: string
  about_us?: string
  photo?: string
}

export type FundInfoResDTO = CreateRes<FundInfoReqDTO>
export type FundOverview = {
  num_funds: 'string'
  total_portfolio: 'string'
  funds_chart: { fund: string; percentage: number }[]
}

export type CreateFundReqDTO = {
  name: string
  size: string
  industry_focus: string[]
  geography_focus: string[]
  closing_date: string
  min_investment: string
  management_fee: string
  info: string
}

export type CreateFundResDTO = CreateFundReqDTO & { id: number }

export type Fund = CreateFundResDTO & {
  is_closed: boolean
  num_investors: number
  avatars: Avatar[]
  committed_amount: number
  uncollected_amount: number
  uninvested_amount: number
  unallotted_amount: number
  collected_amount: number
  fund_entity: FundInfoResDTO
  lp_investment_amount: number
  current_valuation: number
  current_value_of_investments: number
}

interface Avatar {
  src: null
  name: string
  type: string
}
export type Investor = {
  id: number
  name: string
  img: string
  about: string
  investor_type: 'Individual'
  email: string
  phone_number: string
}
export type FinancialInformation = {
  committed_amount: string
  collected_amount: string
}

export type AddInvestorToFundReqDTO = FinancialInformation & {
  first_name: string
  last_name: string
  email: string
  phone_number: string
  investor_type: 'Individual'
  job_title: string
  organisation_name: string
  fund: number
}
export type AddInvestorToFundResDTO = AddInvestorToFundReqDTO & { id: number }

export type Business = {
  id: number
  name: string
  country: string
  website: string
  is_founder: boolean
  logo: string
  industry: string
  product_type: string
  about_us: string
  is_active: boolean
  is_published: boolean
}

export type MakeInvestmentReqDTO = {
  investment_round: InvestmentRound
  investment_amount: string
  date_of_investment: string
  pre_money_valuation: string
  post_money_valuation: string
  current_valuation: string
  time_of_investment_valuation: string

  notes?: string
  portfolio: number
  security_type: SecurityType
  fund?: number
}

export type MakeInvestmentResDTO = CreateRes<MakeInvestmentReqDTO> & {
  portfolio_name: string
  portfolio_logo: string
  current_value_of_investment: string
}

export type Changelog = {
  id: number
  user: User
  previous_value: string
  new_value: string
  created: string
  date: string
  portfolio: number
}
interface User {
  id: number
  email: string
  first_name: string
  last_name: string
  phone_number: string
  avatar: string
}
export type CreateValuationReqDTO = {
  date: string
  value: string
  portfolio: number
}
export type CreateValuationResDTO = CreateRes<CreateValuationReqDTO>

export type AddBusinessToFundReqDTO = {
  website?: string
  name: string
  description: string
  industry: string
  location?: string
  country?: string
  product_type: string
  gender?: string
  data_room?: string
  startup?: number
  fund_business?: number
}
export type AddBusinessToFundResDTO = CreateRes<AddBusinessToFundReqDTO>

export type FundBusiness = AddBusinessToFundResDTO & {
  first_investment_date: string
  investment_amount: number
  current_valuation: number
  valuation_change: number
  investment_status: boolean
  logo?: string
  has_exit: boolean
  total_amount_raised: number
  internal_rate_of_return: number
  multiple_on_invested_capital: number
  return_on_investment: number
  last_valuation_date: string
  entry_investment: number
  entry_valuation: number
  current_value_of_investments: number
}

export type FundMetric = {
  distributed_value: number
  residual_value: number
  paid_in_capital: string
  tvpi: string
  dpi: string
  rvpi: string
}
export type ShareUpdateReqDTO = {
  file: string
  fund: number
  metadata?: any
}
export type ShareUpdateResDTO = CreateRes<ShareUpdateReqDTO>

export const GetFunds = async (data: PaginationReq) => {
  const qs = addDataToQueryString('', data)
  return (
    await api.get<ServerRes<PaginatedData<Fund>>>(FUNDS + (qs ? `?${qs}` : ''))
  ).data.data
}

export const GetLPFundOverview = async () => {
  return (await api.get<ServerRes<FundOverview>>(FUNDS_OVERVIEW)).data.data
}

export const GetFundDetails = async (fundId: number) => {
  return (await api.get<ServerRes<Fund>>(`${FUNDS}${fundId}/`)).data.data
}

export const GetFundMetric = async (fundId: number) => {
  return (await api.get<ServerRes<FundMetric>>(`${FUND_METRIC}${fundId}/`)).data
    .data
}

export const GetAllFundBusinesses = async (data: PaginationReq) => {
  const qs = addDataToQueryString('', { ...data })
  return (
    await api.get<ServerRes<PaginatedData<FundBusiness>>>(
      FUNDS_PORTFOLIO + (qs ? `?${qs}` : '')
    )
  ).data.data
}
export const GetFundBusiness = async (data: {
  fund: number
  business: number
}) => {
  return (
    await api.get<ServerRes<FundBusiness>>(
      FUNDS_PORTFOLIO + `${data.business}/?fund=${data.fund}`
    )
  ).data.data
}

export const GetFundBusinessInvestments = async (
  data: PaginationReq<{ fund: number; portfolio: number }>
) => {
  const qs = addDataToQueryString('', { ...data })
  return (
    await api.get<ServerRes<PaginatedData<MakeInvestmentResDTO>>>(
      FUNDS_INVESTMENT + (qs ? `?${qs}` : '')
    )
  ).data.data
}

export const GetBusinessValuationChart = async (data: {
  fund: number
  business: number
  period: 'month' | 'year'
}) => {
  return (
    await api.get<ServerRes<{ values: number[]; labels: string[] }>>(
      FUNDS_PORTFOLIO +
        `${data.business}/valuation-chart/?period=${data.period}&fund=${data.fund}`
    )
  ).data.data
}

export const GetBusinessChangelog = async (
  data: PaginationReq<{ fund: number; portfolio: number }>
) => {
  const qs = addDataToQueryString('', { ...data })
  return (
    await api.get<ServerRes<PaginatedData<Changelog>>>(
      `${FUNDS_PORTFOLIO}${data.portfolio}/valuation-changelog/` +
        (qs ? `?${qs}` : '')
    )
  ).data.data
}

export const GetSharedUpdates = async (
  data: PaginationReq<{ fund: number }>
) => {
  const qs = addDataToQueryString('', { ...data })
  return (
    await api.get<ServerRes<PaginatedData<ShareUpdateResDTO>>>(
      FUNDS_UPDATES + (qs ? `?${qs}` : '')
    )
  ).data.data
}
